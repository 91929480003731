<template>
    <v-container fluid class="down-top-padding">
        <v-parallax height="205"
                    src="https://images.unsplash.com/photo-1523821741446-edb2b68bb7a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80">
            <p class="mt-2 subheading text-left">
               Hello,
            </p>

            <div>
                <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                    Welcome to our software tools website, your one-stop destination for a wide range of essential tools to enhance your productivity and streamline your workflows. 
                    We offer a comprehensive collection of software tools designed to meet the diverse needs of professionals & developers
                </p>
                <p class="text-caption font-weight-medium font-italic text-left">
                   
                </p>
            </div>
        </v-parallax>
        <!--<v-row>
        <v-col cols="12" lg="4">
            <v-card>
                <v-card-text class="pa-5" >

                    <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Json Editor</h3>
                    <h6 class="subtitle-2 font-weight-light">Different Devices Used to Visit</h6>
                    <div class="pb-6">
                        <v-sparkline :fill="fill"
                                     height="145px"
                                     :gradient="gradient"
                                     :line-width="width"
                                     :padding="padding"
                                     :smooth="radius || false"
                                     :value="value"
                                     auto-draw></v-sparkline>
                    </div>-->
        <!--<div class="d-flex align-center justify-center border-top pt-4 pb-2">
        <div class="d-flex align-center px-3">
            <span class="info--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Mobile</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="deep-purple--text text--lighten-1">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Desktop</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="success--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Tablet</span>
            </span>
        </div>
    </div>-->
        <!--</v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" lg="4">
        <v-card>
            <v-card-text class="pa-5">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Image to Base64</h3>
                <h6 class="subtitle-2 font-weight-light">Different Devices Used to Visit</h6>
                <div class="pb-6">
                    <v-sparkline :fill="fill"
                                 height="145px"
                                 :gradient="gradient"
                                 :line-width="width"
                                 :padding="padding"
                                 :smooth="radius || false"
                                 :value="value"
                                 auto-draw></v-sparkline>
                </div>-->
        <!--<div class="d-flex align-center justify-center border-top pt-4 pb-2">
        <div class="d-flex align-center px-3">
            <span class="info--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Mobile</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="deep-purple--text text--lighten-1">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Desktop</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="success--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Tablet</span>
            </span>
        </div>
    </div>-->
        <!--</v-card-text>
        </v-card>
    </v-col>
    <v-col cols="12" lg="4">
        <v-card>
            <v-card-text class="pa-5">
                <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Signature</h3>
                <h6 class="subtitle-2 font-weight-light">Different Devices Used to Visit</h6>
                <div class="pb-6">
                    <v-sparkline :fill="fill"
                                 height="145px"
                                 :gradient="gradient"
                                 :line-width="width"
                                 :padding="padding"
                                 :smooth="radius || false"
                                 :value="value"
                                 auto-draw></v-sparkline>
                </div>-->
        <!--<div class="d-flex align-center justify-center border-top pt-4 pb-2">
        <div class="d-flex align-center px-3">
            <span class="info--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Mobile</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="deep-purple--text text--lighten-1">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Desktop</span>
            </span>
        </div>
        <div class="d-flex align-center px-3">
            <span class="success--text">
                <span class="overline">
                    <i class="mdi mdi-brightness-1 mr-2"></i>
                </span>
                <span class="font-weight-regular">Tablet</span>
            </span>
        </div>
    </div>-->
        <!--</v-card-text>
        </v-card>
    </v-col>-->
        <!--<v-col cols="12" sm="12">
        <BaseCard heading="Alert - Twitter alert">
            <div>
                <v-list-item-subtitle class="text-wrap line-h20">By combining <span class="grey--text text--darken-4 font-weight-bold">color</span>, <span class="grey--text text--darken-4 font-weight-bold">dismissible</span>, <span class="grey--text text--darken-4 font-weight-bold">border</span>, <span class="grey--text text--darken-4 font-weight-bold">elevation</span>, <span class="grey--text text--darken-4 font-weight-bold">icon</span>, and <span class="grey--text text--darken-4 font-weight-bold">colored-border</span> props you can create stylish custom alerts such as this twitter notification.</v-list-item-subtitle>
                <div class="mt-4">
                    <v-alert v-model="alert"
                             dismissible
                             color="cyan"
                             border="left"
                             elevation="2"
                             colored-border
                             icon="mdi-twitter">
                        You've got
                        <strong>5</strong> new updates on your timeline!.
                    </v-alert>
                    <div class="text-center">
                        <v-btn v-if="!alert" dark @click="alert = true">Reset Alert</v-btn>
                    </div>
                </div>
            </div>
        </BaseCard>
    </v-col>-->
        <!--<v-col cols="12" sm="12">
        <BaseCard heading="Table Simple - Fixed header">
            <div>
                <v-list-item-subtitle class="text-wrap">
                    Use the <code>fixed-header</code> prop together with the <code>height</code> prop to fix the header to the top of the table.
                </v-list-item-subtitle>
                <div class="mt-4">
                    <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Name</th>
                                    <th class="text-left">Calories</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in desserts" :key="item.name">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.calories }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </BaseCard>
    </v-col>-->
        <!--</v-row>-->
        <v-row class="fill-height"
               align="center"
               justify="center">
            <template v-for="(item, i) in items">
                <v-col :key="i"
                       cols="12"
                       md="4">
                    <v-hover v-slot="{ hover }">
                        <v-card :elevation="hover ? 12 : 2"
                                :class="{ 'on-hover': hover }">
                            <v-img :src="item.img"
                                   height="225px">
                                <v-card-title class="text-h6 white--text">
                                    <v-row class="fill-height flex-column"
                                           justify="space-between">
                                        <p class="mt-4 subheading text-left">
                                            {{ item.title }}
                                        </p>

                                        <div>
                                            <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                                                {{ item.text }}
                                            </p>
                                            <p class="text-caption font-weight-medium font-italic text-left">
                                                {{ item.subtext }}
                                            </p>
                                        </div>

                                        <div class="align-self-center" v-show="item.text!=='Coming Soon'">
                                            <v-btn v-for="(icon, index) in icons"
                                                   :key="index"
                                                   :class="{ 'show-btns': hover }"
                                                   :color="transparent"
                                                   icon
                                                   @click="$router.push('jsoneditor')"
                                                   >
                                                <v-icon :class="{ 'show-btns': hover }"
                                                        :color="transparent"
                                                        >
                                                    {{ icon }}
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </v-row>
                                </v-card-title>
                            </v-img>
                        </v-card>
                    </v-hover>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
    const gradients = [
        ["#6c757d"],
        ["#DB7248"],
        ["#6c757d", "#6c757d", "#6c757d"],
        ["#21c1d6", "21c1d6"],
        ["#21c1d6", "#DB7248", "#21c1d6"],
        ["#21c1d6", "#21c1d6", "#21c1d6"]
    ];

    const gradients2 = [
        ["#1e88e5"],
        ["#B7D1BE"],
        ["#1e88e5", "#1e88e5", "#1e88e5"],
        ["#1e88e5", "#1e88e5"],
        ["#1e88e5", "#B7D1BE", "#1e88e5"],
        ["#1e88e5", "#1e88e5", "#1e88e5"]
    ];
export default {
  name: "Home",

        data: () => ({
            icons: ['', 'mdi-arrow-right', ''],
            items: [
                {
                    title: 'Json Editor',
                    text: ``,
                    subtext: 'Format Json file and Text',
                    img: 'https://images.unsplash.com/photo-1557683316-973673baf926?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1129&q=80',
                },
                {
                    title: 'Image to Base64',
                    text: 'Coming Soon',
                    subtext: '',
                    img: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
                },
                {
                    title: 'Signature Generator',
                    text: 'Coming Soon',
                    subtext: '',
                    img: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
                },
                {
                    title: 'QR Code Generator',
                    text: 'Coming Soon',
                    subtext: '',
                    img: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
                },
                {
                    title: 'Meta Tag Generator',
                    text: 'Coming Soon',
                    subtext: '',
                    img: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
                },{
                    title: 'Invoice Generator',
                    text: 'Coming Soon',
                    subtext: '',
                    img: 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
                },
            ],
            transparent: 'rgba(255, 255, 255, 0)',
      alert: true,
      fill: true,
      gradient: gradients[4],
      gradients,
      padding: 8,
      radius: 50,
      value: [0, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 0],
      width: 1,

      fill2: true,
      gradient2: gradients2[4],
      gradients2,
      radius2: 0,
      padding2: 0,
      value2: [1, 2, 5, 9, 5, 10, 3, 5, 1, 8, 2, 9, 4],
      width2: 4,
      desserts: [
          {
              name: 'Frozen Yogurt',
              calories: 159,
          },
          {
              name: 'Ice cream sandwich',
              calories: 237,
          },
          {
              name: 'Eclair',
              calories: 262,
          },
          {
              name: 'Cupcake',
              calories: 305,
          },
          {
              name: 'Gingerbread',
              calories: 356,
          },
          {
              name: 'Jelly bean',
              calories: 375,
          },
          {
              name: 'Lollipop',
              calories: 392,
          },
          {
              name: 'Honeycomb',
              calories: 408,
          },
          {
              name: 'Donut',
              calories: 452,
          },
          {
              name: 'KitKat',
              calories: 518,
          },
      ],
  })
};
</script>
<style scoped>
    .v-card {
        transition: opacity .4s ease-in-out;
    }

        .v-card:not(.on-hover) {
            opacity: 0.6;
        }

    .show-btns {
        color: rgba(255, 255, 255, 1) !important;
    }
</style>